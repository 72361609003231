<template>
  <div class="">
    <b-card>
      <b-card-title>
        <i class="fa fa-dollar mr-1"/> Informações Fiscais
      </b-card-title>
      <div class="row">
        <div class="col-12 col-sm-3 pt-2">
          <b>NCM</b><br />
          <b-input v-model="obj.NCM" />
        </div>
        <div class="col-12 col-sm-3 pt-2">
          <b>GTIN/EAN</b><br />
          <b-input v-model="obj.GTIN" />
        </div>
        <div class="col-12 col-sm-3 pt-2">
          <b>CFOP</b><br />
          <b-input v-model="obj.CFOP" />
        </div>
        <div class="col-12 col-sm-3 pt-2">
          <b>CSON</b><br />
          <b-input v-model="obj.CSON" />
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-12 col-sm-4 pt-2">
          <b>Imposto Estadual</b><br />
          <money
            v-bind="percentMask"
            v-model="obj.ImpostoEstadual"
            class="form-control"
          />
        </div>
        <div class="col-12 col-sm-4 pt-2">
          <b>Imposto Federal</b><br />
          <money
            v-bind="percentMask"
            v-model="obj.ImpostoFederal"
            class="form-control"
          />
        </div> -->
        <div class="col-12 col-sm-4 pt-4" v-if="onGrupo">
          <b-checkbox v-model="obj.atualizarProdutos" switch
            >Atualizar produtos do Grupo ao Salvar</b-checkbox
          >
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    onGrupo: {
      type: Boolean,
      default: false,
    },
    obj: Object,
  },
  mounted() {
    if (!this.obj) {
      this.obj = {
        NCM: "",
        GTIN: "",
        ImpostoFederal: 0,
        ImpostoEstadual: 0,
        atualizarProdutos: true,
      };
    }else{
      if(typeof this.obj == 'string') this.obj = JSON.parse(this.obj)
    }
  console.log("obj", this.obj)
  },
  data() {
    return {};
  },
  watch: {
    "$props.obj": {
      deep: true,
      handler() {
        this.$emit("update", this.obj);
      },
    },
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>