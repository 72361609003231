<template>
  <div>
    <BarraCrud
      :botaoNovo="canAdd"
      :onNovo="onNovo"
      :onFiltrar="filtrar"
      :busca="busca"
      :breadcrumb="breadcrumb"
      :iconeCustomizado="iconeCustomizado"
      :nomeCustomizado="nomeCustomizado"
  
    />
    <GrupoDeProdutosTable
      @salvou="$emit('salvou')"
      :filtro="filtro"
      ref="grupoTable"
      :contentClass="contentClass"
      :canDelete="canDelete"
    />
  </div>
</template>

<script>
import BarraCrud from "@/components/common/BarraCrud";

import GrupoDeProdutosTable from "@/components/GrupoDeProdutos/GrupoDeProdutosTable.vue";
export default {
  components: {
    BarraCrud,
    GrupoDeProdutosTable,
  },
  props: {
    iconeCustomizado: { type: String, default: "" },
    nomeCustomizado: { type: String, default: "" },
    breadcrumb: { type: Boolean, default: true },
    busca: { type: Boolean, default: true },
    contentClass: { type: String, default: "" },
    canDelete: { type: Boolean, default: true },
    canAdd: { type: Boolean, default: true },
  },
  data() {
    return {
      filtro: "",
    };
  },
  methods: {
    filtrar(filtro) {
      this.filtro = filtro;
    },
    onNovo() {
      this.$refs["grupoTable"].novo();
    },
  },
};
</script>

<style></style>
