import axios from "@/utils/axios";

const get = async () => {
  let result = [];
  await axios
    .get("tipo_preco/get")
    .then((data) => {
      result = data.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.error.message);
    });

  return result;
};

// const store = async (data) => {
//   let result;

//   await axios
//     .post("produtosGrupos/store", data)
//     .then((res) => (result = res))
//     .catch((error) => { throw new Error(error.response.data.error.message) });

//   return result;
// };

export default {
  get,
 
};
