<template>
  <div>
    <b-table
      :items="lista"
      :fields="campos"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
      :sort-by="ordem"
    >
      <template #table-busy class="text-center">
        <b-spinner />Carregando...
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="editar(row.item)"
        >
          <i class="fa fa-edit" /> Editar
        </b-button>

        <b-button v-if="canDelete" size="sm" variant="danger" @click="excluir(row.item)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        >
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="lista.length"
      :per-page="paginacao.registros_por_pagina"
    />
    <b-modal
      id="modal-grupo"
      hide-footer
      :content-class="contentClass ? contentClass : 'prodModal'"
      no-fade
    >
      <template #modal-title>
        <i class="fa fa-boxes" />
        {{
          typeof grupo_edit.cod_grupo == "number"
            ? `Editando ${grupo_edit.Grupo}`
            : "Adicionando Grupo de Produtos"
        }}
      </template>
      <GrupoDeProdutosForm
        v-if="grupo_edit"
        :grupo_edit="grupo_edit"
        @salvar="salvar($event)"
        @cancel="cancel"
        :position-unset="contentClass ? true : false"
      />
    </b-modal>
  </div>
</template>

<script>
import ProdutosGrupoLib from "@/libs/ProdutoGrupoLib";
import GrupoDeProdutosForm from "@/components/GrupoDeProdutos/GrupoDeProdutosForm.vue";
import ProdutoGrupoLib from "../../libs/ProdutoGrupoLib";
export default {
  components: {
    GrupoDeProdutosForm,
  },
  props: {
    filtro: String,
    contentClass: { type: String, default: "" },
    canDelete: { type: Boolean, default: true },
  },
  data() {
    return {
      lista: [],
      grupo_edit: {},
      loading: false,
      campos: [
        { key: "cod_grupo", label: "Código", sortable: true },
        { key: "Grupo", label: "Grupo", sortable: true },
        { key: "tipo", label: "Tipo", sortable: true },
        { key: "ordem", label: "Ordem", sortable: true },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 30,
        pagina_atual: 1,
      },
    };
  },
  methods: {
    async carregar() {
      try {
        this.loading = true;
        this.lista = await ProdutosGrupoLib.get();
        console.log('gps', this.lista);
      } catch (error) {
        console.log("erro", error);
        this.showToast(
          "error",
          "Ops! Erro ao carregar os grupos :/\n" + error.message
        );
      } finally {
        this.loading = false;
      }
    },
    limpa() {
      for (const key in this.grupo_edit) {
        this.grupo_edit[key] = "";
      }
    },
    novo() {
      this.limpa();
      this.$bvModal.show("modal-grupo");
    },
    editar(grupo) {
      this.grupo_edit = JSON.parse(JSON.stringify(grupo));
      console.log('gpedt', this.grupo_edit)
      console.log(this.grupo_edit);
      this.$forceUpdate();
      this.$bvModal.show("modal-grupo");
    },
    excluir(grupo) {
      this.$swal({
        title: "Tem ceretza?",
        text: "Deseja excluir este grupo? Não pode ter nenhum produto vinculado á ele!",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Excluir",
      }).then(async (exc) => {
        if (exc.isConfirmed) {
          let res = await ProdutoGrupoLib.excluir({
            cod_grupo: grupo.cod_grupo,
          });
          console.log(res);
          if (res && res.success) {
            this.$swal("Excluido!", "", "success");
            this.carregar();
          } else {
            this.$swal(
              "Erro ao excluir",
              "Verifique se não existe produtos vinculados a este grupo antes!",
              "error"
            );
          }
        }
      });
    },
    showToast(
      icone = "",
      message = "",
      time = 1500,
      description = "",
      confirmButton = false,
      position = "top-end"
    ) {
      this.$swal.fire({
        title: message,
        icon: icone,
        toast: true,
        position: position,
        timer: time,
        showConfirmButton: confirmButton,
        timerProgressBar: true,
        text: description,

        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });
    },
    cancel() {
      this.$bvModal.hide("modal-grupo");
      this.carregar();
    },
    async salvar(grupo) {
      try {
        let res = await ProdutosGrupoLib.store(grupo);
        // console.log(res);
        if (res.data.success) {
          this.showToast("success", "Grupo salvo com sucesso!");
          await this.carregar();
          this.$bvModal.hide("modal-grupo");

          //  this.$store.dispatch("auth/atualizarCardapio");
          this.$emit("salvou");
        }
      } catch (error) {
        this.showToast(
          "error",
          "Ops, alguma coisa deu errado :/\n" + error.message
        );
      }
    },
  },
  mounted() {
    this.carregar();
  },
};
</script>

<style></style>
