<template>
  <div class="">
    <b-tabs>
      <b-tab title="Geral">
        <b-table
          class="shadow-sm p-1 mb-2 bg-white"
          sticky-header="100px"
          :items="tabelaPrecos"
          :fields="campos"
          small
          hover
          outlined
          stacked="sm"
          show-empty
          style="font-size: 11px"
        >
          <template #empty>
            <h4 class="text-center">Nenhum dado para mostrar &#128543;</h4>
          </template>
          <template #cell(nome)="row">
            <b-form-select
              size="sm"
              v-model="row.item.cod_tipo"
              :options="tiposCorretos"
            />
          </template>

          <template #cell(inteira)="row">
            <money
              class="text-center p-1"
              v-model="row.item.inteira"
              size="sm"
              v-bind="money"
            />
          </template>

          <template #cell(meia)="row">
            <money
              v-model="row.item.meia"
              v-bind="money"
              size="sm"
              class="text-center p-1"
            />
          </template>

          <template #cell(actions)="row">
            <b-btn size="sm" @click="onRemove(row.index)" variant="transparent">
              <i class="fas fa-times text-left" />
            </b-btn>
          </template>
        </b-table>
        <b-btn class="" @click="adicionarLinha()">
          <i class="fas fa-plus" /> Adicionar Preço
        </b-btn>
      </b-tab>
      <b-tab title="Empresa">
        <b-tabs class="pt-2" content-class="p-3">
          <b-tab
            v-for="emp in tabelaEmpresa"
            :key="`tabPrecos_${emp.cod_empresa}_${Math.random()}`"
            :title="emp.empresa"
          >
            <select-empresa-caixa
              :empresa="emp.cod_empresa"
              :hideCaixa="true"
              @empresa="(cd) => selectEmpresa(emp, cd)"
            />
            <b-table
              class="shadow-sm p-1 mb-2 bg-white"
              sticky-header="100px"
              :items="emp.tabelaPrecos"
              :fields="campos"
              small
              hover
              outlined
              stacked="sm"
              show-empty
              style="font-size: 11px"
            >
              <template #empty>
                <h4 class="text-center">Nenhum dado para mostrar &#128543;</h4>
              </template>
              <template #cell(nome)="row">
                <b-form-select
                  size="sm"
                  v-model="row.item.cod_tipo"
                  :options="tiposCorretos"
                />
              </template>

              <template #cell(inteira)="row">
                <money
                  class="text-center p-1"
                  v-model="row.item.inteira"
                  size="sm"
                  v-bind="money"
                />
              </template>

              <template #cell(meia)="row">
                <money
                  v-model="row.item.meia"
                  v-bind="money"
                  size="sm"
                  class="text-center p-1"
                />
              </template>

              <template #cell(actions)="row">
                <b-btn
                  size="sm"
                  @click="onRemove(row.index, emp)"
                  variant="transparent"
                >
                  <i class="fas fa-times text-left" />
                </b-btn>
              </template>
            </b-table>
            <b-btn variant="primary" @click="copiarGeral(emp)">
              <i class="fas fa-copy" /> Copiar Geral
            </b-btn>
            <b-btn
              variant="primary"
              class="ml-1"
              @click="adicionarLinhaEmp(emp)"
            >
              <i class="fas fa-plus" /> Adicionar Preço
            </b-btn>
            <b-btn variant="primary" class="ml-1" @click="removerEmp(emp)">
              <i class="fas fa-trash" /> Remover Empresa
            </b-btn>
          </b-tab>
          <template #tabs-end>
            <li role="presentation" class="nav-item align-self-center">
              <b-btn
                variant="none"
                @click="adicionarEmpresa()"
                v-b-popover.hover="'ADICIONAR EMPRESA'"
                ><b-icon-plus-circle />
                <span
                  v-if="!tabelaEmpresa || tabelaEmpresa.length <= 0"
                  class="ml-1"
                  >Adicionar Empresa</span
                >
              </b-btn>
            </li>
          </template>
        </b-tabs>
      </b-tab>
    </b-tabs>
    <div class="row align-items-center">
      <div class="col" v-if="exibeAtualizarTodosProdutos">
        <b-form-checkbox
          class="text-nowrap"
          switch
          v-model="atualizarEmTodosProdutos"
          @change="ConfirmarAtualizarEmTodosProdutos()"
        >
          Atualizar todos produtos
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import ProdutoTipoPrecosLib from "../../libs/ProdutoTipoPrecosLib";
import EmpresasLib from "../../libs/EmpresasLib";
import SelectEmpresaCaixa from "./SelectEmpresaCaixa.vue";

export default {
  components: { SelectEmpresaCaixa },
  props: {
    tabelaPrecos: { type: Array, default: () => [] },
    exibeAtualizarTodosProdutos: { type: Boolean, default: true },
    state: { type: Boolean, deafult: false },
    tabela_preco_empresa: { type: Array },
  },
  data() {
    return {
      campos: [
        { key: "nome", label: "Tipo" },
        { key: "inteira", label: "Inteira", class: "text-center" },
        { key: "meia", label: "Meia", class: "text-center" },
        { key: "actions", label: "", class: "text-center" },
      ],
      optionsTipo: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      atualizarEmTodosProdutos: false,
      tabelaEmpresa: [],
      empresas: [],
    };
  },
  methods: {
    copiarGeral(emp) {
      emp.tabelaPrecos = JSON.parse(JSON.stringify(this.tabelaPrecos));
    },
    adicionarLinhaEmp(emp) {
      emp.tabelaPrecos.push({
        cod_tipo: 0,
        nome: "",
        meia: 0.0,
        inteira: 0.0,
      });
    },
    removerEmp(emp) {
      this.tabelaEmpresa = this.tabelaEmpresa.filter((x) => x != emp);
    },
    selectEmpresa(emp, cod) {
      emp.cod_empresa = cod;
      emp.empresa = this.empresas.find((x) => x.cod_empresa == cod)?.nome;
    },
    adicionarEmpresa() {
      if (!this.tabelaEmpresa) {
        this.tabelaEmpresa = [];
      }
      let nxt = this.empresas.filter(
        (x) =>
          x.cod_empresa !=
          (this.tabelaEmpresa && this.tabelaEmpresa.length > 0
            ? this.tabelaEmpresa[this.tabelaEmpresa.length - 1].cod_empresa
            : 999)
      );
      this.tabelaEmpresa.push({
        cod_empresa: nxt && nxt.length > 0 ? nxt[0].cod_empresa : 0,
        empresa: nxt && nxt.length > 0 ? nxt[0].nome : 0,
        tabelaPrecos: [],
      });
    },
    adicionarLinha() {
      this.tabelaPrecos.push({
        cod_tipo: 0,
        nome: "",
        meia: 0.0,
        inteira: 0.0,
      });
    },
    // onChangeTipo(codTipo) {
    //   if (this.optionsTipo.some((opt) => opt.value == codTipo)) {
    //     this.$set(
    //       this.optionsTipo[
    //         this.optionsTipo.findIndex((opt) => opt.value == codTipo)
    //       ],
    //       "disabled",
    //       true
    //     );
    //   }
    // },
    onRemove(index, emp = null) {
      if (
        (emp && emp.tabelaPrecos[index].cod_tipo) ||
        this.tabelaPrecos[index].cod_tipo
      ) {
        // this.$set(
        //   this.optionsTipo[
        //     this.optionsTipo.findIndex(
        //       (opt) => opt.value == (emp?emp:this).tabelaPrecos[index].cod_tipo
        //     )
        //   ],
        //   "disabled",
        //   false
        // );
      }

      (emp ? emp : this).tabelaPrecos.splice(index, 1);
    },
    async carregaTipos() {
      this.optionsTipo = (await ProdutoTipoPrecosLib.get()).map((tipo) => {
        return { value: tipo.cod_tipo, text: tipo.tipo, disabled: false };
      });
    },
    ConfirmarAtualizarEmTodosProdutos() {
      if (this.atualizarEmTodosProdutos) {
        this.$swal
          .fire({
            title: "Você tem certeza que deseja atualizar todas as tabelas?",
            html: "<p>Quando você salvar, <b>todos produtos deste grupo</b> terão as suas tabelas de preço modificadas.</p>",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.$emit(
                "atualizarEmTodosProdutos",
                this.atualizarEmTodosProdutos
              );
            } else {
              this.atualizarEmTodosProdutos = false;
            }
          });
      }
    },
  },
  async mounted() {
    if (this.tabela_preco_empresa) {
      this.tabelaEmpresa = JSON.parse(
        JSON.stringify(this.tabela_preco_empresa)
      );
    }
    this.empresas = await EmpresasLib.getResumo();
    this.$forceUpdate();
    await this.$nextTick();
    await this.carregaTipos();
    if (this.tabela_preco_empresa) {
      this.tabelaEmpresa = JSON.parse(
        JSON.stringify(this.tabela_preco_empresa)
      );
    }
  },
  watch: {
    tabelaPrecos: {
      // deep: true,
      handler(newVal) {
        // for (let tabela of newVal) {
        //   tabela.meia = tabela.meia.replace('R$ ')
        //   tabela.inteira = tabela.inteira.replace('R$ ')
        // }

        this.$emit("valueChanges", newVal);
      },
      "$props.tabela_preco_empresa": {
        deep: true,
        handler() {
          if (this.tabela_preco_empresa) {
            this.tabelaEmpresa = JSON.parse(
              JSON.stringify(this.tabela_preco_empresa)
            );
            console.log("changed", this.tabelaEmpresa);
            this.$forceUpdate();
          }
        },
      },
    },
    tabelaEmpresa: {
      deep: true,
      handler(newVal) {
        // for (let tabela of newVal) {
        //   tabela.meia = tabela.meia.replace('R$ ')
        //   tabela.inteira = tabela.inteira.replace('R$ ')
        // }
        console.log("asa", newVal);
        this.$emit("valueChangesEmpresa", newVal);
      },
    },

    // matched(newValue) {
    //   console.log(newValue);
    // },
  },
  computed: {
    tiposCorretos() {
      return this.optionsTipo.map((opt) => {
        return {
          ...opt,
        };
      });
    },
  },
};
</script>

<style></style>
