<template>
  <div>
    <b-form @submit.prevent.stop="salvar()">
      <div class="row">
        <div class="col-9">
          <b-form-group label="Nome">
            <b-form-input
              placeholder="Nome"
              v-model="grupo.Grupo"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-8">
          <b-form-group label="Tipo">
            <v-select
              :options="['PIZZA', 'BEBIDA', 'OUTROS']"
              taggable 
              clearable
              v-model="grupo.tipo"
            />
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group label="Ordem">
            <b-input type="number" v-model="grupo.ordem" />
          </b-form-group>
        </div>
      </div>
      <b-form-group>
        <!-- {{ grupo.tabela_preco }} -->
        <div class="row justify-content-center">
          <div class="col-12">
            <TabelaDePrecos
              @valueChanges="grupo.tabela_preco = $event"
              @atualizarEmTodosProdutos="
                grupo.atualizarEmTodosProdutos = $event
              "
              :exibeAtualizarTodosProdutos="editando"
              :tabelaPrecos="grupo.tabela_preco"
              :tabela_preco_empresa="grupo.tabela_preco_empresa"
              @valueChangesEmpresa="(e) => (grupo.tabela_preco_empresa = e)"
            />
          </div>
        </div>
      </b-form-group>

      <FiscalForm
        :obj="grupo.fiscal"
        :onGrupo="true"
        @update="(ev) => (grupo.fiscal = ev)"
      />

      <div class="row">
        <div class="col-12 mt-2">
          <hr />
          <b-card>
            <b-card-title><b-icon-images /> Ícone </b-card-title>
            <file-upload
              v-model="grupo.imagens"
              :toBucket="false"
              :setDefault="true"
              :enableTitle="false"
              :maxFiles="1"
            />
          </b-card>
        </div>
      </div>

      <div
        class="bottom-actions"
        :style="`${positionUnset ? 'position: unset;' : ''}`"
      >
        <hr />
        <b-btn variant="primary" class="mr-3" type="submit"
          ><i class="fa fa-save"></i> Salvar</b-btn
        >
        <b-btn variant="danger" @click="$emit('cancel')"
          ><i class="fa fa-ban"></i> Cancelar</b-btn
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import TabelaDePrecos from "@/components/common/TabelaDePrecos.vue";
import FiscalForm from "../Produtos/FiscalForm.vue";
import FileUpload from "../common/FileUpload.vue";

export default {
  props: {
    grupo_edit: Object,
    positionUnset: { type: Boolean, default: false },
    addOnSave: { type: Boolean, default: false },
  },
  components: {
    TabelaDePrecos,
    FiscalForm,
    FileUpload
  },
  data() {
    return {
      grupo: {
        Grupo: "",
        tabela_preco: [],
        //tabela_preco_empresa:[],
        tipo: "",
        ordem: 0,
        atualizarEmTodosProdutos: false,
        fiscal: {
          NCM: "",
          GTIN: "",
          ImpostoFederal: 0,
          ImpostoEstadual: 0,
          atualizarProdutos: true,
          CFOP: "",
          CSOSN: "",
          imagens:[],
        },
      },
      editando: false,
    };
  },
  methods: {
    async salvar() {
      this.$emit("salvar", this.grupo);
    },
  },
  mounted() {
    if (this.grupo_edit) {
      console.log("RECEBEU", this.grupo_edit);
      if (!this.grupo_edit.fiscal) {
        this.grupo_edit.fiscal = {
          NCM: "",
          GTIN: "",
          ImpostoFederal: 0,
          ImpostoEstadual: 0,
          atualizarProdutos: true,
          CFOP: "",
          CSOSN: "",
        };
      }
      if (!this.grupo_edit.tabela_preco_empresa) {
        this.grupo_edit.tabela_preco_empresa = [];
      }
      if (
        typeof this.grupo_edit.cod_grupo == "number" &&
        this.grupo_edit.cod_grupo > 0
      ) {
        this.editando = true;
        this.grupo = JSON.parse(JSON.stringify(this.grupo_edit));
        console.log("novo gp", this.grupo);
        this.$forceUpdate();
      }
    }
  },
};
</script>
